import React from "react"
import { Link } from "gatsby"

const Footer: React.FunctionComponent = () => {

  return (

    <footer>
        <div className="container-fluid bg-darkBlue py-5">
            <div className="footer-content container-fluid maxWidth">     
                <p className="m-0" style={{textTransform:"none", fontSize:"17px", fontWeight:500}}>
                <Link to="/">
                  <img src="/img/illustrations/IDASSUR_Logo-2_center.bmp" className="d-block mb-3" height="60px" alt="IDASSUR" />
                </Link>    
                  <span>Envíanos un email a</span>
                  <a className="mx-2" href="mailto:info@idassur.es" style={{color:"white"}}>info@idassur.es</a>
                </p>
            </div>
        </div>
    </footer>
  )
}

export default Footer
