import React from "react"
import { Link } from "gatsby"

const Header: React.FunctionComponent = () => {

  function collapseNav() {
    const navbar:any = document.getElementById("navbarMainContent");
    if (navbar.className.includes("show"))
      navbar.className = navbar.className.replace(" show", "");
  }

  return (
    <div id="header-id" className="container-fluid p-0" style={{zIndex:10, backgroundColor:"white", position:"sticky", top:0}}>
      <nav className="navbar main navbar-expand-lg navbar-light">
        <div className="container-fluid maxWidth p-0" style={{minHeight:"60px"}}>          
            <button className="navbar-toggler p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMainContent" aria-controls="navbarMainContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <Link to="/" className="navbar-brand mx-auto my-0 py-0">
              <img src="/img/illustrations/IDASSUR_Logo-2.bmp" className="mx-auto d-block" height="60px" alt="IDASSUR" />
            </Link>            
            <div className="collapse navbar-collapse navbar-expand-sm" id="navbarMainContent">
              <hr className="vertical my-0 mx-3" />
              <ul className="navbar-nav align-items-center ms-auto">
                <li>
                <Link onClick={() => collapseNav()} className="nav-link" aria-current="page" to="/contratar"><button type="button" className="main-button-header" >Contratar</button></Link>
                </li>
              </ul>
            </div>
          </div>
      </nav>
    </div>
  )
}

export default Header
